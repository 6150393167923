import PropTypes from "prop-types";
import React from "react";
import ButtonTestModal from "./ButtonTestModal";

const InformationContent = ({
  className,
  title,
  description,
  textColor,
  subTextColor,
}) => {
  return (
    <div className={`text-center ${className}`}>
      <h1 className={`font-bold md:mt-8 text-${textColor}`}>{title}</h1>
      <h3 className={`py-8 text-${subTextColor}`}>{description}</h3>
      <ButtonTestModal>ENTRAR EM CONTATO</ButtonTestModal>
    </div>
  );
};

InformationContent.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  subTextColor: PropTypes.string,
};

export default InformationContent;
