import React from "react";
import Attendance from "../assets/icons/between.svg";
import Assets from "../assets/icons/book.svg";
import Form from "../assets/icons/gps (1).svg";
import Groups from "../assets/icons/gps.svg";
import Map from "../assets/icons/map.svg";
import Schedule from "../assets/icons/start.svg";
import Reports from "../assets/icons/target (1).svg";
import Target from "../assets/icons/target.svg";
import InformationContent from "../components/InformationContent";
import Layout from "../components/layout";
import ResourcesContent from "../components/ResourcesContent";
import SEO from "../components/seo";

function Recursos() {
  return (
    <Layout>
      <SEO title="AtendeJá" />
      <div className="text-center py-24 px-10 background-blue">
        <h1 className="font-bold text-white">
          Auxiliamos sua empresa em todos os momentos
        </h1>
        <h3 className="text-gray my-8 text-white">
          O AtendeJá auxilia sua empresa a solucionar os desafios mais comuns no
          dia-a-dia do relacionamento com os clientes.
        </h3>
      </div>

      <div className="background-white py-10">
        <ResourcesContent
          title="Atendimentos"
          description="Crie e gerencie atendimentos para sua equipe com agilidade e praticidade."
          image={Attendance}
        />

        <ResourcesContent
          reversed
          title="Agendamento"
          description="Faça um planejamento eficaz dos seus atendimentos para cada colaborador ou equipe."
          image={Schedule}
        />

        <ResourcesContent
          title="Monitoramento"
          description="Monitore em tempo real sua equipe e tenha uma visão geral dos atendimentos."
          image={Target}
        />

        <ResourcesContent
          reversed
          title="Mapa"
          description="Localize sua equipe através do mapa e crie melhores estratégias de atendimento."
          image={Map}
        />

        <ResourcesContent
          title="Criação de equipes"
          description="Cadastre colaboradores e crie equipes específicas para cada segmento ou área."
          image={Groups}
        />

        <ResourcesContent
          reversed
          title="Gestão de ativos"
          description="Tenha controle da gestão de produtos e equipamentos de sua empresa."
          image={Assets}
        />

        <ResourcesContent
          title="Formulário"
          description="Receba atendimentos de clientes através de um formulário no site de sua empresa."
          image={Form}
        />

        <ResourcesContent
          reversed
          title="Relatórios"
          description="Relatórios detalhados e úteis para acompanhar todas as informações de desempenho dos seus atendimentos."
          image={Reports}
        />
      </div>

      <InformationContent
        className="mx-5 md:mx-24 xl:mx-64 my-32"
        title="Junte-se as diversas empresas que confiam no AtendeJá"
        description="Faça parte das empresas que estão crescendo com o AtendeJá e comece a obter melhores resultados"
      />
    </Layout>
  );
}

export default Recursos;
