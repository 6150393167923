import PropTypes from "prop-types";
import React from "react";

const ResourcesContent = ({
  className,
  reversed,
  title,
  description,
  image,
}) => {
  return (
    <div
      className={`${className} md:flex ${
        reversed ? "lg:ml-48 md:ml-32" : "flex-row-reverse lg:mr-48 md:mr-32"
      } items-center justify-center m-8 mt-10 md:mt-0`}
    >
      <img src={image} alt="" className="w-1/4 md:w-1/5" />
      <div
        className={`lg:w-1/2 ${
          reversed ? "lg:ml-32 md:ml-20" : "lg:mr-32 md:ml-20"
        }`}
      >
        <div className="flex items-center">
          <h2 className="font-bold mb-5">{title}</h2>
        </div>
        <h3 className="text-gray">{description}</h3>
      </div>
    </div>
  );
};

ResourcesContent.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  reversed: PropTypes.bool,
  title: PropTypes.string,
};

export default ResourcesContent;
